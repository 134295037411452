
import { defineComponent } from "vue";
import { Ref } from '@vue/reactivity';
import { ref, onMounted, onUnmounted } from 'vue';

export default defineComponent({
  props: ["viewing"],
  methods: {
    onClick(eventName: string, event: any) {
      this.$data.eventSelected = eventName
      console.info('event ' + eventName + '  ' + event)
    }
  },
  data() {
    return {
      eventSelected: this.viewing
    }
  },
  watch: {
    viewing(newValue) {
      console.log('viewing (in nav bar)  ' + newValue)
      this.eventSelected = newValue;
    }
  }
})

// reuseable function for other child elements
export function useIntersectionObserver(
  elementRef: Ref<HTMLElement | null>,
  offset = 0
): Ref<boolean> {
  const isVisible = ref(false);

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        isVisible.value = true;
      } else {
        isVisible.value = false;
      }
    });
  }, { rootMargin: `${offset}px 0px` });

  onMounted(() => {
    if (elementRef.value) {
      observer.observe(elementRef.value);
    }
  });

  onUnmounted(() => {
    if (elementRef.value) {
      observer.unobserve(elementRef.value);
    }
  });

  return isVisible;
}

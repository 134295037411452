<template>
  <div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-main"
      mode="horizontal"
      @select="handleSelect"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      :router="true">
      <li class="el-label">Colin Rogers</li>
      <el-menu-item index="/">Home</el-menu-item>
      <el-menu-item index="/resume">Resume</el-menu-item>
      <!-- <el-menu-item index="/portfolio">Portfolio</el-menu-item> -->
      <el-menu-item index="/projects">Projects</el-menu-item>
    </el-menu>
  </div>
</template>



<script>
export default {
  data () {
    return {
      activeIndex: this.$route.path
    }
  },
  methods: {
    handleSelect (key, keyPath) {
      console.log(key, keyPath)
    }
  },
  mounted: function () {
    this.activeIndex = this.$route.path
  },
  watch: {
    $route (to) {
      this.activeIndex = to.path
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.el-label {
  color:rgb(255, 255, 255);
  display:list-item;
  position: relative;
  font-family:HKGroteskRegular, Helvetica, Arial, sans-serif;
  height:auto;
  list-style-image:none;
  list-style-position:outside;
  list-style-type:none;
  width:auto;
  -webkit-font-smoothing:antialiased;
  float:left;
  height:58px;
  line-height:60px;
  padding-left:25px;
  padding-right:30px;
  font-size:11px;
  font-weight: bold;
  text-transform: uppercase;
  /*text-shadow: -1px -1px 0 rgb(105, 105, 105), 1px -1px 0 rgb(105, 105, 105), -1px 1px 0 rgb(105, 105, 105), 1px 1px 0 rgb(105, 105, 105);*/
  background-color:rgb(44, 47, 51);
}
.el-menu-item a {
  display: block;
  padding: 0 20px;
}
.el-menu-main {
  z-index:1000;
  height: 60px;
}
</style>


import ResumeProfile from '@/components/resume/ResumeProfile.vue'
import ResumeSkills from '@/components/resume/ResumeSkills.vue'
import ResumeWorkHistory from '@/components/resume/ResumeWorkHistory.vue'
import ResumeEducation from '@/components/resume/ResumeEducation.vue'
import ResumeProjects from '@/components/resume/ResumeProjects.vue'
import ResumeNavBar from '@/components/resume/ResumeNavBar.vue'

import { defineComponent } from "vue";

export default defineComponent({
  components: {
    ResumeProfile, ResumeSkills, ResumeWorkHistory, ResumeEducation, ResumeProjects, ResumeNavBar
  },
  data() {
    return {
      viewing: "profile"
    };
  },
  methods: {
    updateViewing(newValue:string) {
      console.info('updateViewing (in main) ' + newValue);
      this.viewing = newValue;
    }
  }
});




import { defineComponent } from "vue";

const MaiaPortfolio = defineComponent({
  // components: {
  //   VueScriptComponent
  // },
  mounted () {
  }
})
export default MaiaPortfolio

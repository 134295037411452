import { createWebHistory, createRouter } from "vue-router";

import HomeMain from './views/HomeMain.vue'
import CharacterCreator from './views/CharacterCreator.vue'
import ResumeMain from './views/ResumeMain.vue'
import PortfolioMain from './views/PortfolioMain.vue'
import ProjectsMain from './views/ProjectsMain.vue'
import WoeBeTidesInfo from './views/WoeBeTidesInfo.vue'
import WoeBeTidesGame from './views/WoeBeTidesGame.vue'
import MaiaPortfolio from './components/portfolio/MaiaPortfolio.vue'
import PiratesPortfolio from './components/portfolio/PiratesPortfolio.vue'
import InshurPortfolio from './components/portfolio/InshurPortfolio.vue'

const routes = [
    {
      path: '/',
      name: 'home-main',
      component: HomeMain
    },
    {
      path: '/resume',
      name: 'resume-main',
      component: ResumeMain
    },
    {
      path: '/portfolio',
      name: 'portfolio-main',
      component: PortfolioMain
    },
    {
      path: '/projects',
      name: 'projects-main',
      component: ProjectsMain
    },
    {
      path: '/dnd-character-creator',
      name: 'dnd-character-creator',
      component: CharacterCreator
    },
    {
      path: '/woe-be-tides',
      name: 'woe-be-tides',
      component: WoeBeTidesInfo
    },
    {
      path: '/woe-be-tides-game',
      name: 'woe-be-tides-game',
      component: WoeBeTidesGame
    },
    {
      path: '/maia-portfolio',
      name: 'maia-portfolio',
      component: MaiaPortfolio
    },
    {
      path: '/pirates-portfolio',
      name: 'pirates-portfolio',
      component: PiratesPortfolio
    },
    {
      path: '/inshur-portfolio',
      name: 'inshur-portfolio',
      component: InshurPortfolio
    }
  ]

  const router = createRouter({
    history: createWebHistory(),
    routes,
  })

  export default router;
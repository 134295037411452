export class Race {
  id: number
  label: string
  attributes: number[]

  constructor (id: number, label: string, attributes: number[]) {
    this.id = id
    this.label = label
    this.attributes = attributes
  }
}

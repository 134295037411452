import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14cb4da6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fixed-navigation" }
const _hoisted_2 = {
  class: "resume-navbar",
  role: "navigation"
}
const _hoisted_3 = { class: "resume-navigation resume-navbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("a", {
            href: "#profile",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick('profile', $event))),
            class: _normalizeClass({active : _ctx.eventSelected === 'profile'})
          }, "Profile", 2)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("a", {
            href: "#skills",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClick('skills', $event))),
            class: _normalizeClass({ active: _ctx.eventSelected === 'skills' })
          }, "Skills", 2)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("a", {
            href: "#history",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClick('history', $event))),
            class: _normalizeClass({ active: _ctx.eventSelected === 'history' })
          }, "History", 2)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("a", {
            href: "#education",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onClick('education', $event))),
            class: _normalizeClass({ active: _ctx.eventSelected === 'education' })
          }, "Education", 2)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("a", {
            href: "#projects",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onClick('projects', $event))),
            class: _normalizeClass({ active: _ctx.eventSelected === 'projects' })
          }, "Projects", 2)
        ])
      ])
    ])
  ]))
}
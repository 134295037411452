<template>
  <div>
    <div class="bar"></div>
    <div class="resume-title">Colin Rogers</div>
    <div class="bigger-bar"></div>
    <div class="homepage-button-container">
      <router-link to="/resume"><div class="homepage-button">Resume and Profile »</div></router-link>
      <!-- <router-link to="/portfolio"><div class="homepage-button">Portfolio »</div></router-link> -->
      <router-link to="/projects"><div class="homepage-button">Personal Projects »</div></router-link>
    </div>
    <div class="spacer"></div>
  </div>
</template>

<style scoped>
  .homepage-button-container {
    width:100%;
    display:block;
  }
  .homepage-button {
    display: block;
    position:relative;
    width:250px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #C98474;
    border:1px solid #C98474;
    border-radius: 25px;
    height:70px;
    color: white;
    font-weight: 600;
    font-size: 1rem;
    padding-top:23px;
    margin-bottom:20px;
  }
  .homepage-button:hover {
    border:1px solid rgb(116, 45, 29);
  }
</style>

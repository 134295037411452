
// import VueScriptComponent from 'vue-script-component'

import { defineComponent } from "vue";

const PiratesPortfolio = defineComponent({
  // components: {
  //   VueScriptComponent
  // },
  mounted () {
  }
})
export default PiratesPortfolio

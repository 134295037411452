
import { Race } from '@/model/Race'

import { defineComponent } from "vue";

const PointsBuy = defineComponent({
  methods: {
    updatePoints () {
      var pointsRemaining = this.$data.pointsMax
      for (let row of this.$data.tableData) {
        var base = row.base
        // console.log(row.name + ' tot=' + tot)
        var points = this.getPoints(base)
        pointsRemaining -= points
        // console.log(row.name + ' points=' + points)
      }
      this.$data.pointsRemaining = pointsRemaining
      // console.log('pointsRemaining=' + pointsRemaining)
    },

    handleRuleChange () {
      for (let row of this.$data.tableData) {
        this.handleBaseChange(row.id, row, false)
      }
      this.updatePoints()
    },

    handleRaceChange (raceIndexSelected:any, event:any) {
      console.log('raceIndexSelected = ' + raceIndexSelected)
      console.log('raceIndexSelected = ' + this.$data.raceIndexSelected)
      console.log('event = ' + event)
      for (let row of this.$data.tableData) {
        this.handleBaseChange(row.id, row, false)
      }
      this.updatePoints()
    },

    handleBaseChange (index:any, row:any, updatePoints:boolean) {
      console.log(index);
      // console.log(row.base);
      // console.log('tf', row.base >= this.$data.maximumPurchasableAttribute);

      if (row.base <= this.$data.minimumPurchasableAttribute) {
        row.base = this.$data.minimumPurchasableAttribute
      }

      if (row.base >= this.$data.maximumPurchasableAttribute) {
        row.base = this.$data.maximumPurchasableAttribute
      }

      // do calc
      // console.log('row.id = ', row.id, ' value = ', row.base)

      if (this.showRaceAttribute(row.id)) {
        var race : Race = this.getRace()
        row.racial = race.attributes[row.id - 1]
      } else {
        var attribute = this.$data.tableData.find(function (elem:any) { if (elem.id === row.id) return elem })
        // console.log('checked = ' + attribute.checked)
        row.racial = attribute && attribute.checked ? 1 : 0
      }
      row.points = this.getPoints(row.base)
      row.total = row.base + row.racial

      row.mod = Math.floor((row.total / 2) - 5)
      // console.log("mod = " + row.mod);

      if (updatePoints) {
        this.updatePoints()
      }
    },
    

    checkDisabledCheckbox (row:any) {
      if (row.checked === false) {
        var count = 0
        for (let thisRow of this.$data.tableData) {
          count += thisRow.checked ? 1 : 0
        }
        // console.log('nos checked = ' + count)
        if (count >= this.getRace().attributes[6]) {
          return true
        }
      }
      return false
    },

    showRaceAttribute (attributeId:any) {
      var race = this.getRace()
      if (race.attributes[6] !== 0) {
        if (race.attributes[attributeId - 1] === 0) {
          return false
        }

        // clear if checked
        var attribute = this.$data.tableData.find(function (elem:any) { if (elem.id === attributeId) return elem })
        if (attribute) {
          attribute.checked = false
        }

        return true
      }
      return true
    },

    getRace () : Race {
      var raceIndexSelected = this.$data.raceIndexSelected
      const race = this.$data.races.find(function (elem:any) { if (elem.id === raceIndexSelected) return elem })
      
      return race ? race : new Race(0, 'UNKNOWN', [2, 0, 0, 0, 0, 1, 0]);
    },

    getPoints (base : number) {
      return this.$data.scores[base - 3].cost
    },

    handleEdit (index:any, row:any) {
      console.log(index, row)
    },

    handleDelete (index:any, row:any) {
      console.log(index, row)
    }
  },

  mounted () {
    this.handleRaceChange(this.$data.raceIndexSelected, null)
  },

  data () {
    return {
      pointsMax: 27,
      pointsRemaining: 27,
      raceIndexSelected: 0,
      maximumPurchasableAttribute: 15,
      minimumPurchasableAttribute: 8,
      scores: [
        { attribute: 3, cost: -9 },
        { attribute: 4, cost: -6 },
        { attribute: 5, cost: -4 },
        { attribute: 6, cost: -2 },
        { attribute: 7, cost: -1 },
        { attribute: 8, cost: 0 },
        { attribute: 9, cost: 1 },
        { attribute: 10, cost: 2 },
        { attribute: 11, cost: 3 },
        { attribute: 12, cost: 4 },
        { attribute: 13, cost: 5 },
        { attribute: 14, cost: 7 },
        { attribute: 15, cost: 9 },
        { attribute: 16, cost: 12 },
        { attribute: 17, cost: 15 },
        { attribute: 18, cost: 19 }],
      races: [
        new Race(1, 'Dragonborn', [2, 0, 0, 0, 0, 1, 0]),
        new Race(2, 'Dwarf, Hill', [0, 0, 2, 0, 0, 1, 0]),
        new Race(3, 'Dwarf, Mountain', [2, 0, 2, 0, 0, 0, 0]),
        new Race(4, 'Elf, Drow', [0, 2, 0, 0, 0, 1, 0]),
        new Race(5, 'Elf, High', [0, 2, 0, 1, 0, 0, 0]),
        new Race(6, 'Elf, Wood', [0, 2, 0, 0, 1, 0, 0]),
        new Race(7, 'Gnome, Forest', [0, 1, 0, 2, 0, 0, 0]),
        new Race(8, 'Gnome, Rock', [0, 0, 1, 2, 0, 0, 0]),
        new Race(9, 'Half-Elf', [0, 0, 0, 0, 0, 2, 2]),
        new Race(10, 'Half-Orc', [2, 0, 1, 0, 0, 0, 0]),
        new Race(11, 'Halfling, Lightfoot', [0, 2, 0, 0, 0, 1, 0]),
        new Race(12, 'Halfling, Stout', [0, 2, 1, 0, 0, 0, 0]),
        new Race(0, 'Human', [1, 1, 1, 1, 1, 1, 0]),
        new Race(13, 'Human, Variant', [0, 0, 0, 0, 0, 0, 2]),
        new Race(14, 'Tiefling', [0, 0, 0, 1, 0, 2, 0])
      ],
      tableData2: [
        new TableData(1, 'STR'),
        new TableData(2, 'DEX'),
        new TableData(3, 'CON'),
        new TableData(4, 'INT'),
        new TableData(5, 'WIS'),
        new TableData(6, 'CHA')
      ],
      tableData: [{
        id: 1,
        name: 'STR',
        base: 8,
        racial: 0,
        points: 0,
        total: 8,
        mod: -1,
        checked: false
      }, {
        id: 2,
        name: 'DEX',
        base: 8,
        racial: 0,
        points: 0,
        total: 8,
        mod: -1,
        checked: false
      }, {
        id: 3,
        name: 'CON',
        base: 8,
        racial: 0,
        points: 0,
        total: 8,
        mod: -1,
        checked: false
      }, {
        id: 4,
        name: 'INT',
        base: 8,
        racial: 0,
        points: 0,
        total: 8,
        mod: -1,
        checked: false
      }, {
        id: 5,
        name: 'WIS',
        base: 8,
        racial: 0,
        points: 0,
        total: 8,
        mod: -1,
        checked: false
      }, {
        id: 6,
        name: 'CHA',
        base: 8,
        racial: 0,
        points: 0,
        total: 8,
        mod: -1,
        checked: false
      }]
    }
  }
});

class TableData {
  id: number
  name: string
  base: number
  racial: number
  points: number
  total: number
  mod: number
  checked: boolean

  constructor (id: number, name: string) {
    this.id = id
    this.name = name
    this.base = 8
    this.racial = 0
    this.points = 0
    this.total = 8
    this.mod = -1
    this.checked = false
  }
}

export default PointsBuy;

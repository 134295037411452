
import PointsBuy from '@/components/PointsBuy.vue'


import { defineComponent } from "vue";

const CharacterCreator = defineComponent({
  components: {
    PointsBuy
  },
  mounted () {
    // console.info('App currentRoute:', this.$route.path)
  },
  data () {
    return {
      activeTab: 'first'
    }
  },
  methods: {
    handleClick (tab:any, event:any) {
      console.log(tab, event)
    }
  }
});

export default CharacterCreator;


// import VueScriptComponent from 'vue-script-component'

import { defineComponent } from "vue";

const InshurPortfolio = defineComponent({
  // components: {
  //   VueScriptComponent
  // },
  mounted () {
  }
})
export default InshurPortfolio;



import { defineComponent } from "vue";

const PortfolioMain = defineComponent({
  components: {
  },
  mounted () {
    console.info('App currentRoute:', this.$route.path)
  }
})
export default PortfolioMain


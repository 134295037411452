
import {defineComponent} from "vue";
import { ref } from 'vue';
// import { Ref } from '@vue/reactivity';
import { useIntersectionObserver } from './ResumeNavBar.vue';

export default defineComponent({
  setup() {
    const workHistoryRef = ref<HTMLElement | null>(null);
    const isVisible = useIntersectionObserver(workHistoryRef, 100);

    return {
      workHistoryRef,
      isVisible
    };
  },
  watch: {
    isVisible(newValue:boolean) {
      console.info('value ' + newValue);
      this.emitEvent(newValue);
    }
  },
  methods: {
    emitEvent(newValue:boolean) {
      console.info('emitEvent ' + newValue);
      if (newValue) {
        // @ts-ignore
        this.$emit("updateViewing", 'history');
      }
    }
  },
});


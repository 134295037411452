
import GenericProjects from '@/components/GenericProjects.vue' // @ is an alias to /src

import { defineComponent } from "vue";

const ProjectsMain = defineComponent({
  components: {
    GenericProjects
  },
  mounted () {
    console.info('App currentRoute:', this.$route.path)
  }
})

export default ProjectsMain;



import { Options, Vue } from 'vue-class-component';
import TenthartNavMenu from './components/TenthartNavMenu.vue';
import "bootstrap"

@Options({
  components: {
    TenthartNavMenu,
  },
})
export default class App extends Vue {}
